// src/components/media/MediaItem.tsx
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import Linkify from 'linkify-react';
import { Link } from 'react-router-dom';
import Gallery from './core/Gallery';
import ReactPlayer from 'react-player';
import { useTheme } from '@mui/material/styles';
import { Post } from '../../redux/classes/Post.class';
import { S3Video } from '../../redux/classes/S3Video.class';

interface Auth {
  id: number;
  perms: { permission: string }[];
}

interface MediaItemProps {
  media: Post;
  auth: Auth;
}

const MediaItem: React.FC<MediaItemProps> = ({ media, auth }) => {
  const theme = useTheme();

  const dateToText = (date: string): string => {
    return moment(date).format('M/D/YY - hh:mm') + 'test';
  };

  const getEditButton = (media: Post): JSX.Element | null => {
    if (auth && media.userId === auth.id) {
      return (
        <Button
          component={Link}
          to={`/media/edit/${media.id}`}
          variant="outlined"
          color="info"
          startIcon={<EditIcon />}
          sx={{ mt: 1 }}
        >
          Edit
        </Button>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 3,
        width: '100%',
      }}
    >
      <Card
        sx={{
          width: '90%',
          maxWidth: '1000px',
          bgcolor: theme.palette.background.paper,
          border: `4px solid ${
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.5)'
              : 'rgba(0,0,0,0.4)'
          }`,
          boxShadow:
            theme.palette.mode === 'dark'
              ? '6px 6px 15px rgba(0,0,0,0.7)'
              : '6px 6px 15px rgba(0,0,0,0.2)',
          p: 1,
          borderRadius: '12px',
        }}
      >
        {/* Header */}
        <CardHeader
          title={media.authorName}
          titleTypographyProps={{
            sx: { color: theme.palette.text.primary, fontWeight: 'bold' },
          }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            p: 2,
            borderRadius: '8px 8px 0 0',
          }}
        />

        <Divider sx={{ bgcolor: theme.palette.divider }} />

        {/* Main Content */}
        <CardContent
          sx={{
            p: 3,
            bgcolor: theme.palette.background.default,
          }}
        >
          <Linkify>
            <h5
              style={{
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                marginBottom: '10px',
              }}
            >
              {media.content}
            </h5>
          </Linkify>

          {/* Render photos if available */}
          {media.photos && media.photos.length > 0 && <Gallery photos={media.photos} />}

          {/* Render s3videos if available */}
          {media.s3videos && media.s3videos.length > 0 && (
            <Box sx={{ mt: 2 }}>
              {media.s3videos.map((video: S3Video) => (
                <Box key={video.id} sx={{ mb: 2 }}>
                  <ReactPlayer url={video.amazons3URL} width="100%" height="auto" controls />
                  <Typography variant="caption" display="block">
                    {video.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {getEditButton(media)}
        </CardContent>

        <Divider sx={{ bgcolor: theme.palette.divider }} />

        {/* Footer */}
        <Box
          sx={{
            p: 2,
            textAlign: 'right',
            bgcolor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
            {dateToText(media.createdAt)}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default MediaItem;
